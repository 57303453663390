import { useAnalytics } from '@fc/app-common';
import sessionData from '@/shared/utils/sessionData';
import { transformShopifyProductForAnalytics } from '~/selectors/selectors';

const analytics = useAnalytics();

// Needs this to be true so it does not conflict with the existing shared actions
export const namespaced = true;

// TODO handle errors
class CouponError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CouponError';
  }
}

export const actions = {
  async createCheckout({ rootGetters, rootState, commit }, { shippingDetails, cartCoupon = null }) {
    console.log('store.js - action - createCheckout', cartCoupon);
    const { checkoutId } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });
      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: cartCoupon,
      }, checkoutId);
    } catch (err) {
      this.$bugsnag.notify(err);
      if (err.name === 'ProductAvailabilityError') {
        commit('SET_SHIPPING_ERROR', err.message, { root: true });
        commit('SET_SHIPPING_INVALID', ['country'], { root: true });
      } else if (err.name === 'CouponError') {
        commit('updateCouponError', err.message, { root: true });
      } else {
        commit('SET_SHIPPING_ERROR', err, { root: true });
      }
    } finally {
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  async updateCheckout({ rootGetters, rootState, commit }, { shippingDetails = null, cartCoupon = null } = {}) {
    const { checkoutId } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });
      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart,
      }, checkoutId);

      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: cartCoupon,
      }, checkoutId);
    } catch (err) {
      this.$bugsnag.notify(err);
      if (err.name === 'ProductAvailabilityError') {
        commit('SET_SHIPPING_ERROR', err.message, { root: true });
        commit('SET_SHIPPING_INVALID', ['country'], { root: true });
      } else if (err.name === 'CouponError') {
        commit('updateCouponError', err.message, { root: true });
      } else {
        commit('SET_SHIPPING_ERROR', err, { root: true });
      }
    } finally {
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  async getOrder({ rootState }) {
    console.log('store.js - action - getOrder');
    try {
      const { paymentIntentId, checkoutId } = rootState.checkout;
      if (!paymentIntentId || !checkoutId) { return null; }

      // use to return paymentIntent order from backend - it used to calculatute total but now that's Shopifys job
      // what would be the purpose of returning the paymentIntent from the backend now?
      // const resp = await this.$ordersApi.getOrder(paymentIntentId);
      const {
        totalPrice, subTotal, totalTax, shippingRates, discountAmount, couponCode, couponData,
      } = await getCheckoutUntilReady({ checkoutId, eCommService: this.$eCommService });

      return {
        order: {
          costBreakdown: {
            totalCost: totalPrice,
            productTax: totalTax,
            productCost: subTotal,
            // TODO refactor coupon data to one obj once isShopifyOn is removed, did not want to mess with existing pattern
            ...(discountAmount ? { discountAmount } : {}),
            ...(couponCode ? { couponName: couponCode } : {}),
            ...(couponData ? { couponData } : {}),
            productShipping: shippingRates ? shippingRates[0]?.price : null,
          },
          shippingRateDetails: shippingRates ? shippingRates[0] : null, // had to add this to be able to update order in applycoupon
        },
      };
    } catch (err) {
      console.error(err);
      this.$bugsnag.notify(err);

      return null;
    }
  },

  async createOrder({
    commit, rootGetters, rootState,
  }, { shippingDetails }) {
    console.log('store.js - action - createOrder');
    try {
      commit('setProcessingTotalCost', true, { root: true });

      const {
        cart, membershipInCart, order, cartProducts,
      } = rootGetters;

      const cartItems = cart.map((item) => ({
        id: item.id,
        qty: item.qty,
        type: item.type,
        uid: cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
      }));

      const tags = createOrderTags({ isEligibleGen2Upgrade: rootGetters.isEligibleGen2Upgrade, includesConsole: rootGetters.includesConsole });
      const isOnlySecondHandMembershipInCart = cart.length === 1 && (membershipInCart?.variants[0]?.sku === 'FC-Secondhand-Membership');
      const isAffirmWithPackage = rootState.checkout.paymentInformation?.paymentMethod === 'affirm' && membershipInCart;

      await createOrUpdateOrder({
        shippingDetails,
        cart: cartItems,
        commit,
        order,
        ordersApi: this.$ordersApi,
        useSetupIntent: isOnlySecondHandMembershipInCart || isAffirmWithPackage,
        checkoutState: rootState.checkout,
        ...(tags ? { tags } : {}),
      });

      commit('setProcessingTotalCost', false, { root: true });
    } catch (err) {
      console.error(err);
      this.$bugsnag.notify(err);

      if (err.name === 'ProductAvailabilityError') {
        commit('SET_SHIPPING_ERROR', err.message, { root: true });
        commit('SET_SHIPPING_INVALID', ['country'], { root: true });
      } else {
        commit('SET_SHIPPING_ERROR', err, { root: true });
      }
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  async updateOrder({
    commit, rootGetters, rootState,
  }, payload) {
    console.log('store.js - action - updateOrder');
    try {
      commit('setProcessingTotalCost', true, { root: true });

      const { shippingDetails } = payload;
      const {
        cart, membershipInCart, order, cartProducts,
      } = rootGetters;
      const { paymentIntentId } = rootState.checkout;

      const isOnlySecondHandMembershipInCart = cart.length === 1 && (membershipInCart?.variants[0]?.sku === 'FC-Secondhand-Membership');
      const isAffirm = rootState.checkout.paymentInformation?.paymentMethod === 'affirm';
      const cartItems = cart.map((item) => ({
        id: item.id,
        qty: item.qty,
        type: item.type,
        uid: cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
      }));
      const tags = createOrderTags({ isEligibleGen2Upgrade: rootGetters.isEligibleGen2Upgrade, includesConsole: rootGetters.includesConsole });

      await createOrUpdateOrder({
        shippingDetails,
        cart: cartItems,
        commit,
        order,
        ordersApi: this.$ordersApi,
        useSetupIntent: isOnlySecondHandMembershipInCart || isAffirm,
        checkoutState: rootState.checkout,
        ...(tags ? { tags } : {}),
      }, paymentIntentId);
    } catch (err) {
      console.error(err);
      this.$bugsnag.notify(err);
      commit('setProcessingTotalCost', false, { root: true });
      commit('SET_SHIPPING_ERROR', err, { root: true });
    }
    commit('setProcessingTotalCost', false, { root: true });
  },

  async confirmOrder({
    commit, rootState, rootGetters,
  }) {
    commit('SET_PROCESSING_PAYMENT_METHOD', true, { root: true });
    commit('SET_PAYMENT_ATTEMPT_ERROR', '', { root: true });

    console.log('store.js - action - confirmOrder');
    const {
      paymentIntentId, draftOrderId, setupIntentId, shipping, cart, customerId, affirmToken, order: { costBreakdown, shippingRateDetails }, new_customer: newCustomer, checkoutEmail: email,
    } = rootState.checkout;
    const { cartProducts } = rootGetters;
    try {
      if (!draftOrderId) {
        throw new Error('Draft Order ID is missing');
      }

      if (paymentIntentId) {
        // need to update the Payment Intent with the customerId so that it attaches the customer to the card for subscriptions
        // and lets also update shipping details, cart, costBreakdown in case they have changed
        const cartItems = cart.map((item) => ({
          id: item.id,
          qty: item.qty,
          type: item.type,
          uid: cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
        }));
        const tags = createOrderTags({ isEligibleGen2Upgrade: rootGetters.isEligibleGen2Upgrade, includesConsole: rootGetters.includesConsole });
        const updateRequestBody = mapPaymentIntentRequest({
          shippingDetails: shipping, cart: cartItems, costBreakdown, customerId, draftOrderId, tags,
        });

        await this.$ordersApi.updateOrder({ paymentIntentId, requestBody: updateRequestBody });
      }

      const membership = rootGetters?.membershipInCart ? {
        id: rootGetters?.membershipInCart?.metadata?.stripeSubId,
        trialDays: parseInt(rootGetters.membershipInCart.metadata.stripeTrialDays, 10),
        stripeCoupon: rootGetters.membershipInCart.metadata.stripeCoupon,
      } : null;

      const productList = cart.map((item) => ({
        id: item.id,
        qty: item.qty,
        type: item.type,
        uid: rootGetters?.cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
      }));

      const requestBody = {
        email,
        shippingInfo: {
          address: shipping.address,
          address2: shipping.address2,
          city: shipping.city,
          state: shipping.state,
          country: shipping.country,
          postalCode: shipping.postalCode,
          firstName: shipping.firstName,
          lastName: shipping.lastName,
          phoneNumber: shipping.phoneNumber,
          email,
        },
        costBreakdown,
        shippingRateDetails,
        productList,
        membership,
        affirmLoanId: affirmToken,
        customerId,
        paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id,
        options: {
          paymentMethod: rootState.checkout.paymentInformation?.paymentMethod,
          retail: null,
          insideSale: null,
        },
        sessionData: sessionData.get(),
        newCustomer,
        paymentIntentId,
        draftOrderId,
        setupIntentId,
        anonymousId: typeof window?.analytics?.user === 'function' ? window.analytics.user()?.anonymousId() : null,
      };

      const {
        data: {
          orderName, orderID, method, experiments,
        },
      } = await this.$ordersApi.confirmOrder({ requestBody });

      commit('setConfirmation', {
        email,
        textPhoneNumber: shipping.phoneNumber,
        orderNumber: parseInt(orderName.split('#')[1], 10), // orderNumber needs to be an integer and cannot have # in it
        orderID,
        order: rootState.checkout.order,
        method,
        cart,
        isNewCustomer: newCustomer,
        serverExperiments: experiments, // TODO
        name: `${shipping.firstName} ${shipping.lastName}`,
        subscription: rootGetters.membershipInCart,
      }, { root: true });

      const products = rootGetters?.cartProducts?.shopifyItems?.map(item => transformShopifyProductForAnalytics({ product: item, variantId: item?.variant?.id }));

      analytics.orderCompleted({ ...rootGetters.confirmation, products, packageInCart: !!rootGetters.packageInCart });

      commit('reset', null, { root: true });
    } catch (err) {
      this.$bugsnag.notify(err);
      if (err?.response?.data.type === 'StripeError') {
        throw new Error(err.response.data.message);
      }

      if (err?.response?.data.type === 'ShopifyError' && err?.response?.data.message === 'Error completing draft order in Shopify') {
        throw new Error('Sorry, there was an error placing the order. Please refresh and try again.');
      }

      if (err.message === 'Draft Order ID is missing') {
        throw new Error('Sorry, there was an error placing the order. Please refresh and try again.');
      }

      throw new Error(err);
    }
  },

  // handles sending a request to retrieve an existing customer from backend
  async getCustomer({ commit }, payload) {
    console.log('store.js - action - getCustomer');
    let customer = {};

    try {
      const { email, id } = payload;
      const response = await this.$ordersApi.getCustomer({ email, id });
      [customer] = response.data;

      if (customer) {
        commit('updateCustomerId', customer.id, { root: true });
        commit('setNewCustomer', customer.newSubCustomer, { root: true });
      }

      return customer;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },

  // handles sending a request to create a new customer from backend
  async createCustomer({ commit }, payload) {
    console.log('store.js - action - createCustomer');
    let createdCustomer = {};

    try {
      const { shippingDetails } = payload;

      const requestBody = {
        email: shippingDetails.email,
        shippingDetails: {
          name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
          phone: shippingDetails.phoneNumber,
          address: {
            line1: shippingDetails.address,
            line2: shippingDetails.address2,
            city: shippingDetails.city,
            state: shippingDetails.state,
            country: shippingDetails.country,
            postal_code: shippingDetails.postalCode,
          },
        },
      };

      createdCustomer = await this.$ordersApi.createCustomer(requestBody);

      commit('updateCustomerId', createdCustomer.data.id, { root: true });
      commit('setNewCustomer', createdCustomer.data.newSubCustomer, { root: true });

      return createdCustomer.data;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },

  async updateCustomer({ rootState }, payload = {}) {
    console.log('store.js - action - updateCustomer');
    let updatedCustomer = null;
    const { shippingDetails = null, id: stripeId } = payload;

    try {
      const id = stripeId || rootState.checkout.customerId;

      const requestBody = {
        paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id ?? null,
        ...(shippingDetails && {
          shippingDetails: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            phone: shippingDetails.phoneNumber,
            address: {
              line1: shippingDetails.address,
              line2: shippingDetails.address2,
              city: shippingDetails.city,
              state: shippingDetails.state,
              country: shippingDetails.country,
              postal_code: shippingDetails.postalCode,
            },
          },
        }),
      };

      updatedCustomer = await this.$ordersApi.updateCustomer({ id, requestBody });

      return updatedCustomer.data;
    } catch (err) {
      this.$bugsnag.notify(err);

      if (err?.response?.data.type === 'StripeError') {
        throw new Error(err.response.data.message);
      } else {
        throw new Error(err);
      }
    }
  },

  async applyCoupon({ rootState, rootGetters, commit }, coupon) {
    console.log('store.js - action - applyCoupon');
    const { checkoutId, checkoutEmail, shipping } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });

      // why combine shippingDetails??
      const shippingDetails = { ...shipping, email: checkoutEmail };

      await createOrUpdateShopifyCheckout({
        shippingDetails, eCommService: this.$eCommService, commit, cart: rootGetters.cart, couponName: coupon,
      }, checkoutId);

      commit('updateCouponError', '', { root: true });
      commit('updateCoupon', coupon, { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    } catch (err) {
      commit('setProcessingTotalCost', false, { root: true });
      console.error(err);
      this.$bugsnag.notify(err);

      // TODO handle displaying correct error message
      commit('updateCouponError', err.message, { root: true });
    }
  },
  async removeCoupon({ rootState, commit }) {
    console.log('store.js - action - removeCoupon');
    const { checkoutId } = rootState.checkout;
    try {
      commit('setProcessingTotalCost', true, { root: true });

      await createOrUpdateShopifyCheckout({
        eCommService: this.$eCommService, commit, removeCoupon: true,
      }, checkoutId);

      commit('updateCouponError', '', { root: true });
      commit('updateCoupon', '', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    } catch (err) {
      commit('setProcessingTotalCost', false, { root: true });
      console.error(err);
      this.$bugsnag.notify(err);

      // TODO handle displaying correct error message
      commit('updateCouponError', 'We are sorry. There was an error removing your promo code', { root: true });
    }
  },
};

/*
  Recursive function that calls Shopify API query getCheckout until the checkout is ready
  if for some reason the checkout is not ready after 20 seconds we stop making calls to Shopify
  and throw an error
*/
async function getCheckoutUntilReady({ checkoutId, eCommService, startTime = Date.now() }) {
  // If more than 20 seconds have passed since the first call, throw an error
  if (Date.now() - startTime > 20000) {
    throw new Error('Sorry, there was an error signing you up. Please try again.');
  }

  const checkoutData = await eCommService.getCheckout(checkoutId);
  if (checkoutData.isReady) {
    return checkoutData;
  }
  // Wait for 2 seconds before the next API call
  await new Promise(resolve => setTimeout(resolve, 2000));
  return getCheckoutUntilReady({ checkoutId, eCommService, startTime });
}

/**
 * Creates a Shopify checkout with the provided shipping details and updates the store's order object.
 *
 * @param {Object} options - The options for creating the Shopify checkout.
 * @param {string} checkoutId - The Shopify Checkout ID if it already exists.
 * @param {Object} options.shippingDetails - The shipping details for the checkout.
 * @param {Object} options.eCommService - The e-commerce service used to create the checkout.
 * @param {Function} options.commit - The Vuex commit function used to update the store.
 * @param {Array} options.cart - The cart items to be included in the checkout.
 * @returns {Promise<Object>} - The order object for the created checkout.
 */
async function createOrUpdateShopifyCheckout({
  shippingDetails = null, eCommService, commit, cart = [], couponName, removeCoupon = false,
}, checkoutId = null) {
  let shippingAddress = null;

  if (shippingDetails) {
    const {
      address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
    } = shippingDetails;

    shippingAddress = {
      address1: address,
      address2,
      city,
      province: state,
      country,
      firstName,
      lastName,
      phone: phoneNumber,
      zip: postalCode,
    };
  }

  const request = {
    ...(shippingDetails?.email && { email: shippingDetails.email }),
    ...(shippingAddress && { shippingAddress }),
    lineItems: cart.map((item) => ({
      variantId: item.id,
      quantity: item.qty,
    })),
  };
  let id = checkoutId;

  try {
    if (id) {
      const updatedCheckout = await eCommService.updateCheckout({ ...request, checkoutId: id });
      id = updatedCheckout.checkoutId;

      if (updatedCheckout.error) {
        throw new Error(updatedCheckout.error);
      }

      // Apply Coupon
      if (couponName) {
        const updatedCouponCheckout = await eCommService.applyCoupon({ checkoutId: id, couponCode: couponName });

        if (updatedCouponCheckout.checkoutId) {
          id = updatedCouponCheckout.checkoutId;
        }

        /*
        Shopify will not give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
        */
        if (updatedCouponCheckout.errors.length > 0) {
          // only handling showing the first error for now
          throw new CouponError(updatedCouponCheckout.errors[0]);
        }
      }

      // remove coupon
      if (removeCoupon) {
        const updatedRemoveCoupCheckout = await eCommService.removeCoupon({ checkoutId: id });

        if (updatedRemoveCoupCheckout.checkoutId) {
          id = updatedRemoveCoupCheckout.checkoutId;
        }

        if (updatedRemoveCoupCheckout.errors.length > 0) {
          throw new CouponError('We are sorry. There was an error removing your promo code', { root: true });
        }
      }

      commit('setCheckoutId', id, { root: true });
    } else {
      const createdCheckout = await eCommService.createCheckout(request);
      commit('setCheckoutId', createdCheckout.checkoutId, { root: true });
      id = createdCheckout.checkoutId;

      // Apply Coupon
      if (couponName) {
        const updatedCouponCheckout = await eCommService.applyCoupon({ checkoutId: id, couponCode: couponName });

        if (updatedCouponCheckout.checkoutId) {
          id = updatedCouponCheckout.checkoutId;
        }

        /*
        Shopify will not give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
        */
        if (updatedCouponCheckout.errors.length > 0) {
          // only handling showing the first error for now
          throw new CouponError(updatedCouponCheckout.errors[0]);
        }
      }
    }

    const {
      shippingRates,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    // shippingRates may be null if the product does not require shipping or empty array
    if (shippingRates && shippingRates?.length > 0) {
      console.log('shippingRates:', shippingRates, 'shippingRates[0]:', shippingRates[0], 'id:', id);
      await eCommService.updateCheckoutShippingLine({ checkoutId: id, shippingRateHandle: shippingRates[0].id });
    }

    // TODO move these calculations to seperate function and can move coupon calls out of this function as well?
    // get these values at the end until all the mutations are done and data is ready
    const {
      subTotal, totalPrice, totalTax, shippingAmount, totalDuties, discountAmount, couponCode, couponData,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    // format Shopify checkout data to match our old order object format, this means minimal changes to the store. Can be refactored later.
    const orderObj = {
      costBreakdown: {
        totalCost: totalPrice,
        productTax: totalTax,
        productCost: subTotal,
        productShipping: shippingAmount,
        ...(discountAmount ? { discountAmount } : {}),
        ...(couponCode ? { couponName: couponCode } : {}), // follow existing pattern
        ...(couponData ? { couponData } : {}),
      },
      shippingRateDetails: shippingRates ? shippingRates[0] : null,
      totalDuties,
    };
    console.log('orderObj:', orderObj);
    commit('updateOrder', orderObj, { root: true });

    return orderObj;
  } catch (err) {
    if (err.name === 'ShippingError' && err.message === 'MISSING_SHIPPING_RATE') {
      throw new Error('Product currently not available.');
    }

    if (err.name === 'CouponError') {
      throw new CouponError(err.message);
    }

    throw new Error(err.message);
  }
}

/**
 * Creates a Stripe payment intent and commits the ID in the Vuex store.
 *
 * @param {Object} options - The options for creating the payment intent.
 * @param {Object} stripePaymentIntentId - The Stripe PaymentIntentId if it already exists.
 * @param {Object} options.shippingDetails - The shipping details for the order.
 * @param {Object[]} options.cart - The items in the cart.
 * @param {Function} options.commit - The Vuex commit function.
 * @param {Object} options.order - Order info
 * @param {Object} options.order.costBreakdown - The cost breakdown of the order.
 * @param {Object} options.order.shippingRateDetails - The shipping details of the order.
 * @param {Object} options.ordersApi - The API for creating orders.
 * @param {Boolean} options.useSetupIntent - Whether to use SetupIntent instead of Payment Intent.
 * @param {Object} options.checkoutState - The state of the checkout.
 * @returns {Promise<Object>} - A promise that resolves when the payment intent is created.
 */
async function createOrUpdateOrder({
  shippingDetails, cart, commit, order, ordersApi, useSetupIntent = false, checkoutState, tags,
}, stripePaymentIntentId = null) {
  let setupIntentId = null;
  const customerId = checkoutState.customerId;

  if (useSetupIntent) {
    const paymentMethodId = checkoutState?.paymentInformation?.paymentMethodObj?.id ?? null;

    const setupIntent = await ordersApi.createSetupIntent({ customerId, paymentMethodId });
    setupIntentId = setupIntent.data.id;
    commit('setSetupIntentId', setupIntentId, { root: true });
  }

  const { costBreakdown, shippingRateDetails } = order;

  const existingDraftOrderId = checkoutState?.draftOrderId;
  const paymentMethod = checkoutState?.paymentInformation?.paymentMethod;
  const orderRequestBody = mapPaymentIntentRequest({
    shippingDetails,
    cart,
    costBreakdown,
    shippingRateDetails,
    setupIntentId,
    draftOrderId: existingDraftOrderId,
    paymentMethod,
    ...(customerId ? { customerId } : {}),
    ...(tags ? { tags } : {}),
  });

  if (stripePaymentIntentId) {
    const res = await ordersApi.updateOrder({ paymentIntentId: stripePaymentIntentId, requestBody: orderRequestBody });
    console.log('response of updating payment intent:', res);
  } else {
    const { data: { paymentIntentId, draftOrderId } } = await ordersApi.createOrder({ requestBody: orderRequestBody });
    commit('setOrderIds', { paymentIntentId, draftOrderId }, { root: true });
  }
}

function mapPaymentIntentRequest({
  shippingDetails, cart, costBreakdown, shippingRateDetails = null, customerId = null, setupIntentId = null, draftOrderId = null, tags = null, paymentMethod = null,
}) {
  const {
    email, address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
  } = shippingDetails;
  return {
    order: {
      currency: 'usd',
      email,
      itemList: cart,
      shippingDetails: {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        phone: phoneNumber,
        address,
        address2,
        city,
        state,
        country,
        postalCode,
      },
      ...(tags ? { tags } : {}),
    },
    shippingRateDetails,
    costBreakdown,
    customerId,
    setupIntentId,
    draftOrderId,
    paymentMethod,
  };
}

function createOrderTags({ isEligibleGen2Upgrade, includesConsole }) {
  const tags = [];

  if (isEligibleGen2Upgrade) {
    tags.push('Gen2 Existing Customer Promo');
  }

  if (includesConsole) {
    tags.push('includesConsole');
  }

  return tags;
}
