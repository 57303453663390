// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'vue-router';
import navTypes from '~/store/constants/navTypes';

export const navigationTypeRoutesMap: Map<Route['name'], navTypes> = new Map([
  [null, navTypes.secondary],
  ['about-us', navTypes.secondary],
  ['android', navTypes.secondary],
  ['financing', navTypes.secondary],
  ['id-verification', navTypes.secondary],
  ['legal-terms', navTypes.secondary],
  ['privacy-policy', navTypes.secondary],
  ['trackers', navTypes.secondary],
  ['shop', navTypes.shop],
  ['shop-equipment', navTypes.shop],
  ['shop-products-fightcamp-connect', navTypes.shop],
  ['shop-products-fightcamp-personal', navTypes.shop],
  ['shop-products-fightcamp-tribe', navTypes.shop],
  ['shop-products-fightcamp-trackers', navTypes.shop],
  ['shop-products-fightcamp-trackers-bag', navTypes.shop],
  ['shop-marketplace', navTypes.marketplace],
  ['shop-beta', navTypes.checkout],
  ['shop-nda', navTypes.checkout],
  ['press', navTypes.secondary],
  ['what-is-fightcamp', navTypes.secondary],
  ['why-choose-fightcamp', navTypes.secondary],
  ['gqbox', navTypes.gq],
  ['partner-offer', navTypes.gq],
  ['new-fightcamp-console', navTypes.checkout],
  ['shop-products-fightcamp', navTypes.gq],
]);

export const notMarketableShopRoutesMap: Set<Route['name']> = new Set([
  'shop-marketplace',
  'shop',
  'shop-beta',
  'shop-nda',
  'shop-products-fightcamp-console-bag',
  'shop-products-fightcamp-the-works',
  'shop-products-fightcamp-all-in-one',
  'shop-products-fightcamp-console',
]);
