//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { FocusTrap } from 'focus-trap-vue';
import { FCInput, FCButton } from '@fc/angie-ui';
import FCImage from '~/components/FCImage.vue';
import { EMAIL_VALIDATOR } from '~/constants/regex';
import toggleScroll from '~/utils/toggleScroll';
import ButtonLink from '@/shared/components/ButtonLink.vue';

export const defaultCopy = {
  title: 'Download the FightCamp brochure!',
  text: 'Get access to the FightCamp digital brochure.',
  buttonGet: 'Learn More About FightCamp',
  buttonAbandon: "No thanks, I'm not interested",
};

Vue.component('FocusTrap', FocusTrap);
export default Vue.extend({
  name: 'BrochureModal',
  components: {
    FCInput,
    FCButton,
    FCImage,
    ButtonLink,
  },
  props: {
    id: { type: String, default: '' },
    embed: Boolean,
  },
  data: () => ({
    modalActive: true,
    email: '',
    emailError: false,
    emailErrorMsg: '',
    step: 0,
    phoneNumber: '',
    phoneNumberError: false,
    phoneNumberErrorMsg: '',
    token: null,
    loading: false,
  }),
  mounted() {
    if (!this.embed) {
      const name = 'BrochurePopUp';
      this.$analytics.campaignViewed({
        name,
        type: 'modal',
      });
    }

    toggleScroll(this.modalActive);
  },
  computed: {
    ...mapGetters(['navType']),
    isNotSubmitted() {
      return this.step === 0;
    },
    isSubmitted() {
      return this.step === 1;
    },
    buttonTheme() {
      return this.loading ? 'button-disabled' : 'button-primary';
    },
  },
  methods: {
    validateEmail() {
      this.emailErrorMsg = '';
      if (this.email === '') {
        return;
      }
      this.emailError = !EMAIL_VALIDATOR.test(String(this.email).toLowerCase());
      if (this.emailError) {
        this.emailErrorMsg = 'Email format invalid.';
      }
    },
    async onSuccess(token) {
      this.token = token;
    },
    onError(err) {
      this.token = null;
      this.emailError = true;
      this.emailErrorMsg = err;
    },
    async getbrochure() {
      this.resetError();
      this.loading = true;
      if (!this.token) {
        this.emailError = true;
        this.emailErrorMsg = 'reCaptcha required.';
        return;
      }

      const recaptchaRes = await axios.post('/.netlify/functions/recaptcha', { token: this.token });
      if (!recaptchaRes.statusCode === 200) {
        this.token = null;
        this.emailError = true;
        this.emailErrorMsg = 'Please try again.';
        return;
      }

      this.validateEmail();
      if (this.emailError) {
        return;
      }
      if (this.email === '') {
        this.emailError = true;
        this.emailErrorMsg = 'Please enter your email.';
        return;
      }

      try {
        const res = await this.$api_client.validateEmail(
          this.email.toLowerCase(),
        );
        if (!res.emailValid) {
          throw new Error(
            'Invalid Email. Please check for errors or try with another email.',
          );
        }
        this.step = 1;
        this.$ecommAnalytics.identifyByEmail(this.email);
        this.$analytics.emailIdentified(this.email);
        this.$bugsnag.setUser({ email: this.email });

        this.$ecommAnalytics.brochurePopUp('Subscribe');
        this.$analytics.campaignSubscribed({
          name: 'BrochurePopUp',
          type: 'modal',
          email: this.email,
        });

        await this.$store.dispatch('identifyByEmail', this.email);
        this.loading = false;
      } catch (error) {
        this.emailError = true;
        this.emailErrorMsg = error.message;
        this.loading = false;
      }
    },
    resetError() {
      this.emailError = false;
      this.emailErrorMsg = '';
    },
    closeModal() {
      this.$emit('close');
      this.modalActive = false;
      toggleScroll(false);
    },
  },
  beforeDestroy() {
    this.modalActive = false;
    toggleScroll(false);
  },
  watch: {
    modalActive() {
      toggleScroll(this.modalActive);
    },
  },
});
